<template>
    <div>
        <div class="page-header justify-content-start">
            <lable class="page-title">Document</lable>
            <!-- <ul class="breadcrumbs">
                <li>Raj Sinha > Pending Task (04)</li>
            </ul> -->
        </div>
        <section class="tasks-section">
            <div class="tasks-header">
                <div class="task-filters-left">
                    <!-- <ul>
                        <li v-on:click="isActive='All'" v-bind:class="{ active: isActive=='All' }">All</li>
                        <li v-on:click="isActive='High'" v-bind:class="{ active: isActive=='High' }" >High</li>
                        <li v-on:click="isActive='Medium'" v-bind:class="{ active: isActive=='Medium' }" >Medium</li>
                        <li v-on:click="isActive='Low'" v-bind:class="{ active: isActive=='Low' }" >Low</li>
                    </ul> -->
                </div>
                <div class="task-filters-right">
                    <ul>
                        <li>
                            <label>CATEGORY</label>
                            <multiselect
                                v-model="categorySelected"
                                :show-labels="false" 
                                :options="categoryOptions">
                            </multiselect>
                        </li>
                        <li>
                            <label>SUB - CATEGORY</label> 
                            <multiselect
                                v-model="subCategorySelected" 
                                :show-labels="false" 
                                :options="subCategoryOptions">
                            </multiselect>
                        </li>
                        <li>
                            <label>TIME RANGE</label> 
                            <div class="date_range">
                                <dateRange></dateRange>                                 
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="document-filters">
                <ul>
                    <li v-on:click="isActive='All'" v-bind:class="{ active: isActive=='All' }" class="all">All</li>
                    <li v-on:click="isActive='Pdf'" v-bind:class="{ active: isActive=='Pdf' }" ><img src="@/assets/images/pdf.svg">PDF</li>
                    <li v-on:click="isActive='Xsl'" v-bind:class="{ active: isActive=='Xsl' }" ><img src="@/assets/images/xsl.svg">Xsl</li>
                    <li v-on:click="isActive='Doc'" v-bind:class="{ active: isActive=='Doc' }" ><img src="@/assets/images/doc.svg">Doc</li>
                    <li v-on:click="isActive='Img'" v-bind:class="{ active: isActive=='Img' }" ><img src="@/assets/images/img.svg">Img</li>
                    <li v-on:click="isActive='Vid'" v-bind:class="{ active: isActive=='Vid' }" ><img src="@/assets/images/vid.svg">Vid</li>
                    <li v-on:click="isActive='AUD'" v-bind:class="{ active: isActive=='AUD' }" ><img src="@/assets/images/aud.svg">AUD</li>
                    <li v-on:click="isActive='Link'" v-bind:class="{ active: isActive=='Link' }" ><img src="@/assets/images/link.svg">Link</li>
                </ul>
            </div>
            <div class="table-actions padb20">
                <div class="d-flex align-items-center">
                    <a>Select All</a>
                    <label>3 members selected</label>
                </div>
                <div class="table-actions-right">
                    <a v-b-modal.delete ><img src="@/assets/images/delete.svg">DELETE</a>
                    <a><img src="@/assets/images/download.svg">IMPORT</a>
                </div>
            </div>
            <div class="task-wrap documents_wrap">
                <div class="task-list ">
                    <ul>
                        <li class="category">
                            <b-form-checkbox></b-form-checkbox>
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Promotion - Ravi B</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li  class="document-type">
                            <div class="task-item">
                                <label>document name</label>
                                <div class="task-content"><a href="#">Promotion Letter.<br/> Ravi B</a></div>
                            </div>
                            <div class="task-item">
                                <label>document type</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small>PDF</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>uploaded by</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>SHARED WITH</label>
                                 <div class="task-content">
                                    <div class="profile-sec profile-all">
                                        <figure></figure>
                                        <figcaption>All</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>UPLOADED ON</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>SHARED ON</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         
                        <li class="task-actions">
                            <div class="task-item">
                                <label>ACCESS TYPE</label>
                                <div class="task-content"><p>Individual</p></div>
                            </div>
                            <div class="task-item">
                                <label>ACTION</label>
                                <div class="task-content">
                                    <div class="popover_tabs_wrap">       
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            TAKE ACTION
                                            </button>
                                            <div class="popper">
                                                <div class="popover_tabs access_tabs">
                                                <div class="popover_tab_title">
                                                    <h5>Access Type</h5>
                                                </div>
                                                <b-tabs>
                                                    <b-tab active>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/lock.svg"></figure>
                                                            <em>Private</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/unlock.svg" >
                                                                </figure>
                                                            <em>Public</em> 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/Union.svg" >
                                                            </figure>
                                                            <em>Group</em>                                                 
                                                        </template> 
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <div class="group_search">
                                                                    <span>Group Name</span>
                                                                    <input type="text" placeholder="Meditation Group" class="form-control" />
                                                                </div> 
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/single.svg"></figure>
                                                            <em>Individual</em> 
                                                        </template> 
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <div class="group_search">
                                                                    <span>Group Name</span>
                                                                    <input type="text" placeholder="Meditation Group" class="form-control" />
                                                                </div>
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab> 

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/add-note.svg"></figure>
                                                            <em>Add Note</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab> 
                                                </b-tabs>
                                            </div>
                                            </div> 
                                        </popper>
                                    </div>
                                    <!-- <a class="activity" href="#">View Note</a>     -->
                                    <div class="popper_cnt"> 
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            View Note
                                            </button>
                                            <div class="popper">
                                                <div class="popper_head">
                                                    <h5>View Note</h5>
                                                </div>
                                                <div class="activity_table">
                                                    <table>
                                                        <thead>                                                        
                                                            <tr>
                                                                <th>actioned on</th> 
                                                                <th>NOTE BY</th> 
                                                                <th>NOTE</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody> 
                                                            <tr>
                                                                <td>3rd Nov ‘20<span>04:04 pm</span></td> 
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Sai Pallavi</figcaption>
                                                                    </div>
                                                                </td> 
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2nd Nov ‘20<span>10:15 am</span></td> 
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td> 
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr> 
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </popper>                                
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="task-list ">
                    <ul>
                        <li class="category">
                            <b-form-checkbox></b-form-checkbox>
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Promotion - Ravi B</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li  class="document-type">
                            <div class="task-item">
                                <label>document name</label>
                                <div class="task-content"><a href="#">Promotion Letter.<br/> Ravi B</a></div>
                            </div>
                            <div class="task-item">
                                <label>document type</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small>PDF</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>uploaded by</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>SHARED WITH</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>UPLOADED ON</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>SHARED ON</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         
                        <li class="task-actions">
                            <div class="task-item">
                                <label>ACCESS TYPE</label>
                                <div class="task-content"><p>Individual</p></div>
                            </div>
                            <div class="task-item">
                                <label>ACTION</label>
                                 <div class="task-content">
                                    <div class="popover_tabs_wrap">       
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            TAKE ACTION
                                            </button>
                                            <div class="popper">
                                                <div class="popover_tabs access_tabs">
                                                <div class="popover_tab_title">
                                                    <h5>Access Type</h5>
                                                </div>
                                                <b-tabs>
                                                    <b-tab active>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/lock.svg"></figure>
                                                            <em>Private</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/unlock.svg" >
                                                                </figure>
                                                            <em>Public</em> 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/Union.svg" >
                                                            </figure>
                                                            <em>Group</em>                                                 
                                                        </template> 
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <div class="group_search">
                                                                    <span>Group Name</span>
                                                                    <input type="text" placeholder="Meditation Group" class="form-control" />
                                                                </div> 
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/single.svg"></figure>
                                                            <em>Individual</em> 
                                                        </template> 
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <div class="group_search">
                                                                    <span>Group Name</span>
                                                                    <input type="text" placeholder="Meditation Group" class="form-control" />
                                                                </div>
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab> 

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/add-note.svg"></figure>
                                                            <em>Add Note</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab> 
                                                </b-tabs>
                                            </div>
                                            </div> 
                                        </popper>
                                    </div>
                                    <!-- <a class="activity" href="#">View Note</a>     -->
                                    <div class="popper_cnt"> 
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            View Note
                                            </button>
                                            <div class="popper">
                                                <div class="popper_head">
                                                    <h5>View Note</h5>
                                                </div>
                                                <div class="activity_table">
                                                    <table>
                                                        <thead>                                                        
                                                            <tr>
                                                                <th>actioned on</th> 
                                                                <th>NOTE BY</th> 
                                                                <th>NOTE</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody> 
                                                            <tr>
                                                                <td>3rd Nov ‘20<span>04:04 pm</span></td> 
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Sai Pallavi</figcaption>
                                                                    </div>
                                                                </td> 
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2nd Nov ‘20<span>10:15 am</span></td> 
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td> 
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr> 
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </popper>                                
                                    </div>
                                </div> 
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="task-list ">
                    <ul>
                        <li class="category">
                            <b-form-checkbox></b-form-checkbox>
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Promotion - Ravi B</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li  class="document-type">
                            <div class="task-item">
                                <label>document name</label>
                                <div class="task-content"><a href="#">Promotion Letter.<br/> Ravi B</a></div>
                            </div>
                            <div class="task-item">
                                <label>document type</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small>PDF</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>uploaded by</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>SHARED WITH</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>UPLOADED ON</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>SHARED ON</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         
                        <li class="task-actions">
                            <div class="task-item">
                                <label>ACCESS TYPE</label>
                                <div class="task-content"><p>Individual</p></div>
                            </div>
                            <div class="task-item">
                                <label>ACTION</label>
                                <div class="task-content">
                                    <div class="popover_tabs_wrap">       
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            TAKE ACTION
                                            </button>
                                            <div class="popper">
                                                <div class="popover_tabs access_tabs">
                                                <div class="popover_tab_title">
                                                    <h5>Access Type</h5>
                                                </div>
                                                <b-tabs>
                                                    <b-tab active>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/lock.svg"></figure>
                                                            <em>Private</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/unlock.svg" >
                                                                </figure>
                                                            <em>Public</em> 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/Union.svg" >
                                                            </figure>
                                                            <em>Group</em>                                                 
                                                        </template> 
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <div class="group_search">
                                                                    <span>Group Name</span>
                                                                    <input type="text" placeholder="Meditation Group" class="form-control" />
                                                                </div> 
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/single.svg"></figure>
                                                            <em>Individual</em> 
                                                        </template> 
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <div class="group_search">
                                                                    <span>Group Name</span>
                                                                    <input type="text" placeholder="Meditation Group" class="form-control" />
                                                                </div>
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab> 

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/add-note.svg"></figure>
                                                            <em>Add Note</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab> 
                                                </b-tabs>
                                            </div>
                                            </div> 
                                        </popper>
                                    </div>
                                    <!-- <a class="activity" href="#">View Note</a>     -->
                                    <div class="popper_cnt"> 
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            View Note
                                            </button>
                                            <div class="popper">
                                                <div class="popper_head">
                                                    <h5>View Note</h5>
                                                </div>
                                                <div class="activity_table">
                                                    <table>
                                                        <thead>                                                        
                                                            <tr>
                                                                <th>actioned on</th> 
                                                                <th>NOTE BY</th> 
                                                                <th>NOTE</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody> 
                                                            <tr>
                                                                <td>3rd Nov ‘20<span>04:04 pm</span></td> 
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Sai Pallavi</figcaption>
                                                                    </div>
                                                                </td> 
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2nd Nov ‘20<span>10:15 am</span></td> 
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td> 
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr> 
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </popper>                                
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="task-list ">
                    <ul>
                        <li class="category">
                            <b-form-checkbox></b-form-checkbox>
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Promotion - Ravi B</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li  class="document-type">
                            <div class="task-item">
                                <label>document name</label>
                                <div class="task-content"><a href="#">Promotion Letter.<br/> Ravi B</a></div>
                            </div>
                            <div class="task-item">
                                <label>document type</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small>PDF</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>uploaded by</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>SHARED WITH</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>UPLOADED ON</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>SHARED ON</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         
                        <li class="task-actions">
                            <div class="task-item">
                                <label>ACCESS TYPE</label>
                                <div class="task-content"><p>Individual</p></div>
                            </div>
                            <div class="task-item">
                                <label>ACTION</label>
                                <div class="task-content">
                                    <div class="popover_tabs_wrap">       
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            TAKE ACTION
                                            </button>
                                            <div class="popper">
                                                <div class="popover_tabs access_tabs">
                                                <div class="popover_tab_title">
                                                    <h5>Access Type</h5>
                                                </div>
                                                <b-tabs>
                                                    <b-tab active>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/lock.svg"></figure>
                                                            <em>Private</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/unlock.svg" >
                                                                </figure>
                                                            <em>Public</em> 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/Union.svg" >
                                                            </figure>
                                                            <em>Group</em>                                                 
                                                        </template> 
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <div class="group_search">
                                                                    <span>Group Name</span>
                                                                    <input type="text" placeholder="Meditation Group" class="form-control" />
                                                                </div> 
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/single.svg"></figure>
                                                            <em>Individual</em> 
                                                        </template> 
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <div class="group_search">
                                                                    <span>Group Name</span>
                                                                    <input type="text" placeholder="Meditation Group" class="form-control" />
                                                                </div>
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab> 

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/add-note.svg"></figure>
                                                            <em>Add Note</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab> 
                                                </b-tabs>
                                            </div>
                                            </div> 
                                        </popper>
                                    </div>
                                    <!-- <a class="activity" href="#">View Note</a>     -->
                                    <div class="popper_cnt"> 
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            View Note
                                            </button>
                                            <div class="popper">
                                                <div class="popper_head">
                                                    <h5>View Note</h5>
                                                </div>
                                                <div class="activity_table">
                                                    <table>
                                                        <thead>                                                        
                                                            <tr>
                                                                <th>actioned on</th> 
                                                                <th>NOTE BY</th> 
                                                                <th>NOTE</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody> 
                                                            <tr>
                                                                <td>3rd Nov ‘20<span>04:04 pm</span></td> 
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Sai Pallavi</figcaption>
                                                                    </div>
                                                                </td> 
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2nd Nov ‘20<span>10:15 am</span></td> 
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td> 
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr> 
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </popper>                                
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div> 
            </div>
        </section> 
        <b-modal ref="my-modal" id="delete" centered hide-header hide-footer>
            <span class="close"  @click="hideModal"><span aria-hidden="true">&times;</span></span>
            <div class="alert-messages">
                <label>Delete 3 member ?</label>
                <div class="modal-foot">
                    <button @click="hideModal">CANCEL</button>
                    <button @click="hideModal">YES</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect-inv";
import Popper from 'vue-popperjs';
import dateRange from '@/components/dateRange.vue'
import 'vue-popperjs/dist/vue-popper.css';
  export default {
    components: {
        Multiselect,
        dateRange,
        Popper
    },
    data() {
      return {
        isActive: 'All',
        selected: null,
        categorySelected: 'All',
        subCategorySelected: 'All',
        TimeSelected: 'All',
        filteroptions: [
          { value: null, text: 'All' },
          { value: 'a', text: 'Option 1' },
          { value: 'b', text: 'Option 2' },
          { value: 'c', text: 'Option 3' },
          { value: 'd', text: 'Option 4',}
        ],
        options: ['list', 'of', 'options'],
        categoryOptions: ['All ', 'Publication & Media', 'Project', 'Administration' ,'People Connect'],
        subCategoryOptions: ['All ', 'Book', 'Interview', 'Vedio'],
        TimeOptions: ['All ', 'Time 1', 'Time 2', 'Time 3'],
      }
    },
     methods: {
      showModal() {
        this.$refs['my-modal'].show() 
      },
      hideModal() {
        this.$refs['my-modal'].hide() 
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn') 
      },
     




      
    },
  }
</script>